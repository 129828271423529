import Pannellum from "pannellum-react/lib/elements/Pannellum";

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardContent from "@mui/material/CardContent";
import DialogContent from '@mui/material/DialogContent';


const ShowPanorama = function(props){
    console.log("P--", props)

    return (
        <div>
            <Dialog
                open={props.show}
                onClose={props.handleShow}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                sx={{
                    '& .MuiPaper-root.MuiPaper-elevation': {margin: "4px"}
            }}
            >
                <DialogContent dividers={true} sx={{padding:"0", border:"0"}}>
                    <Card sx={{ maxWidth: 400}}>
                        <IconButton
                            aria-label="close"
                            onClick={props.handleShow}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                backgroundColor: "rgb(72 72 72 / 12%)",
                                zIndex: 300
                            }}
                        >
                            <CloseIcon sx={{fill: "white"}}/>
                        </IconButton>

                            <Pannellum
                                width="376px"
                                height="300px"
                                image={ props.data.img_path }
                                haov={360}      //Initial horizontal angle of view
                                vaov={90}       //Initial vertical angle of view
                                vOffsect={160}   //Initial vertical offset angle
                                yaw={110}       //Starting yaw position in degrees
                                pitch={6}       //Starting pitch position in degrees
                                hfov={50}      //Starting horizontal field of view in degrees
                                minHfov={40}  // максимальное приближение
                                maxHfov={60}  // минимальное приближение
                                minPitch={-40} //максимальный угол вниз
                                maxPitch={40}  //максимальный угол вверх
                                avoidShowingBackground={true}
                                autoLoad
                                title={props.data.name}
                                orientationOnByDefault={false}
                                draggable
                                keyboardZoom
                                mouseZoom
                                preview=""
                                showControls
                                showFullscreenCtrl={false}
                                showZoomCtrl={false}
                                /*onLoad={()=>{console.log("panorama loaded");}}
                                onScenechange={(id)=>{console.log("Scene has change on " + id);}}
                                onScenechangefadedone={()=>{console.log("panorama loaded");}}
                                onError={(err)=>{console.log("Error" , err);}}
                                onErrorcleared={()=>{console.log("Error Cleared");}}
                                onMousedown={(evt)=>{console.log("Mouse Down" , evt);}}
                                onMouseup={(evt)=>{console.log("Mouse Up", evt);}}
                                onTouchstart={(evt)=>{console.log("Touch Start", evt);}}
                                onTouchend={(evt)=>{console.log("Touch End", evt);}}*/
                                hotspotDebug={false}
                            >
                                {/*
                                <Pannellum.Hotspot
                                    type="custom"
                                    pitch={31}
                                    yaw={150}
                                    handleClick={(evt , args) => this.hanldeClickImage(evt , args)}
                                    handleClickArg={{ "name":"test" }}
                                />
                                */}
                            </Pannellum>
                    </Card>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default ShowPanorama
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import RoomIcon from '@mui/icons-material/Room';
import CircularProgress from '@mui/material/CircularProgress';

export default function SearchAnimalInput(props) {
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { width: '266px', margin:'0 5px', padding:'0'},

            }}
            noValidate
            autoComplete="off"
        >
            <TextField id="search" label="Пошук" type="search" onChange={(e) => props.handleSearch(e)}/>

            {props.data.processing && <CircularProgress color="inherit" />}

            {props.data.matches.length > 0 &&
                <List component="nav">
                    { props.data.matches.map( (el, index) => (
                        <ListItemButton key={index} onClick={(e) => props.handleMatch(e, el)}>
                            <ListItemIcon sx={{minWidth:"30px"}}>
                                <RoomIcon />
                            </ListItemIcon>
                            <ListItemText primary={`${el.name}` } />
                        </ListItemButton>)
                    )}
                </List>}
        </Box>
    );
}
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {DialogActions} from "@mui/material";
import {Button} from "@mui/material";


const ShowEnableGpsDialog = function(props){
    const txt = "<p>Для відображення Вашого місцезнаходження увімкніть <span class='gps_icon'></span>&nbsp;GPS на пристрої, та оновіть сторінку.</p>"

    return (
        <div>
            <Dialog
                open={props.show}
                onClose={props.handleShow}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                sx={{
                    '& .MuiPaper-root.MuiPaper-elevation': {margin: "4px"}
                }}
            >
                <DialogContent dividers={true}>
                    <div
                        dangerouslySetInnerHTML={{__html : txt}}
                        style={{fontWeight:"400",fontSize:"1rem",lineHeight:"1.43",letterSpacing:"0.008em",color:"#424242"}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=> window.location.reload()}>Оновити сторінку</Button>
                    <Button onClick={props.handleShow}>Закрити</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ShowEnableGpsDialog
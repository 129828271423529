const infrastructure = [
    {
        name: "Перша вхідна група",
        code: "entrance_1",
        geodata: [50.00198, 36.22964],
        description: "",
        exp_img: false,
        icon: "entrance",
    },
    {
        name: "Друга вхідна група",
        code: "entrance_2",
        geodata: [50.00436, 36.22483],
        description: "",
        exp_img: false,
        icon: "entrance",
    },
    {
        name: "Кафе \"Genacvale cafe\"",
        code: "restaurant_1",
        geodata: [50.002635, 36.227155],
        description: "",
        exp_img: false,
        icon: "restaurant",
    },
    {
        name: "Кафе \"Cafe Amico Zoo\"",
        code: "restaurant_2",
        geodata: [50.003264131644734, 36.223539649798305],
        description: "",
        exp_img: false,
        icon: "restaurant",
    },
    {
        name: "Кафе \"Zoo Cafe\"",
        code: "restaurant_3",
        geodata: [50.00162, 36.22958],
        description: "",
        exp_img: false,
        icon: "restaurant",
    },
    {
        name: "Міні-кафе \"Panda Zoo\" cafe",
        code: "minicafe_1",
        geodata: [50.00321930830948, 36.22288519080344],
        description: "",
        exp_img: false,
        icon: "cafe"
    },
    {
        name: "Міні-кафе \"Panda Zoo\" cafe",
        code: "minicafe_2",
        geodata: [50.00261, 36.22652],
        description: "",
        exp_img: false,
        icon: "cafe"
    },
    {
        name: "Міні-кафе \"Panda Zoo\" cafe",
        code: "minicafe_3",
        geodata: [50.00223, 36.22385],
        description: "",
        exp_img: false,
        icon: "cafe"
    },
    {
        name: "Театр природи",
        code: "theatre",
        geodata: [50.002390, 36.227623],
        description: "Театр природи.",
        exp_img: false,
        icon: "theatre"
    },
    {
        name: "Дитячий майданчик",
        code: "playground_1",
        geodata: [50.00181, 36.22926],
        description: "",
        exp_img: false,
        icon: "playground"
    },
    {
        name: "Дитячий майданчик",
        code: "playground_2",
        geodata: [50.00270, 36.22753],
        description: "",
        exp_img: false,
        icon: "playground"
    },
    {
        name: "Дитячий майданчик",
        code: "playground_3",
        geodata: [50.00339, 36.22373],
        description: "",
        exp_img: false,
        icon: "playground"
    },
    {
        name: "Туалет",
        code: "wc_1",
        geodata: [50.00205, 36.22923],
        description: "",
        exp_img: false,
        icon: "wc"
    },
    {
        name: "Туалет",
        code: "wc_2",
        geodata: [50.00441, 36.22521],
        description: "",
        exp_img: false,
        icon: "wc"
    },
    {
        name: "Туалет",
        code: "wc_3",
        geodata: [50.00208, 36.2237],
        description: "",
        exp_img: false,
        icon: "wc"
    },
    {
        name: "Адміністрація",
        code: "administration",
        geodata: [50.00451, 36.2257],
        description: "",
        exp_img: false,
        icon: "office"
    },
    {
        name: "Магазин сувенірів",
        code: "shop_1",
        geodata: [50.00437, 36.22502],
        description: "",
        exp_img: false,
        icon: "shop"
    },
    {
        name: "Магазин сувенірів",
        code: "shop_2",
        geodata: [50.00181, 36.22961],
        description: "",
        exp_img: false,
        icon: "shop"
    },
]

export default infrastructure
const animals_other = [{
    // Укр. название
    name: "Крокодил нільський",
    // Уникальный код (животные одного вида могут быть на разных экспозициях)
    // в таком случае код должен отличаться т.к. будут разные фото экспозиций
    // и возможно разные описания
    code: "nile_crocodile",
    // ID страницы на сайте для фетчинга (пока не используется)
    id: 110,
    // Координаты
    geodata: [50.00284, 36.22361],
    // Описание на укр.
    description: "Найбільший та настрашніший хижак африканських річок і озер.",
    // Дополнительное фото снизу (обычно это общий вид экспозиции)
    // Файл должен иметь название ${code}_exp.jpg
    exp_img: true,
    // Название svg-файла для иконки
    icon: "nile_crocodile"
},
    {
    name: "Алігатор міссісіпський",
    code: "alligator",
    id: 110,
    geodata: [50.00294, 36.2252],
    description: "Володар озер і кипарисових боліт Флориди.",
    exp_img: true,
    icon: "alligator"
},
]

export default animals_other
import Box from "@mui/material/Box";
import logo from "../icons/logo.png";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import StyledAppBar from "./styled_appbar"

const AppBar = (props) => {
    return(
        <StyledAppBar component="nav" sx={{padding:"10px 0"}}>
            <Box sx={{display:"flex",direction:"raw"}}>
                <img src={logo} className="App-logo" alt="logo" style={{width:"100px", marginRight:"10px", height:"auto"}}/>
                <GpsFixedIcon sx={ () =>{
                    const s = {borderRadius: "100%",fontSize:"1.6em"}
                    props.geoCoords.available ? s.fill = "white" : s.fill="gray"
                    props.mapCenter.deviceOnCenter && props.geoCoords.available ? s.border = "2px solid white" : s.border = "none"
                    props.mapCenter.deviceOnCenter && props.geoCoords.available ? s.padding = "4px" : s.padding = "6px"
                    return s
                }
                }  onClick={props.centerGeoPosition}/>
            </Box>
            <Box sx={{display:"flex",direction:"raw"}}>
                <IconButton onClick={props.handleSearchDrawer} sx={{marginRight:"10px"}}>
                    <SearchIcon
                        edge="end"
                        style={{fill:"white"}}
                    />
                </IconButton>
                <IconButton onClick={props.handleSettingsDrawer} sx={{marginRight:"10px"}}>
                    <MenuIcon
                        edge="end"
                        style={{fill:"white"}}
                    />
                </IconButton>
            </Box>
        </StyledAppBar>
    )
}

export default AppBar
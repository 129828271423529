import React, {useEffect, useState, useRef} from "react";

import L from "leaflet"
import {MapContainer, ImageOverlay, TileLayer, Marker, useMap, Circle} from 'react-leaflet'

import { ThemeProvider } from '@mui/material/styles';
import mytheme from "./mytheme";

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import {ChevronRightOutlined} from "@mui/icons-material";
import ExploreOffIcon from '@mui/icons-material/ExploreOff';


import AppBar from "./components/appBar";
import AppButtons from "./components/appButtons";
import DrawerHeader from "./components/styled_drawer_header";
import ShowInfo from "./components/showInfo";
import SearchAnimalInput from "./components/search"
import SettingsForm from "./components/settings";
import ShowPanorama from "./components/showPanorama";
import ShowEnableGpsDialog from "./components/showEnableGps";

import consts from "./consts";
import animals_mammals from "./objects/animals_mammals";
import animals_birds from "./objects/animals_birds";
import animals_other from "./objects/animals_other";
import infrastructure from "./objects/infrastructure";
import panorams from "./objects/panorams";

import imgLayerMap from "./img/map.png";

import "leaflet-routing-machine";
import "./leaflet-routing-machine/dist/leaflet-routing-machine.css"
import ShowEvents from "./components/showEvents";


const img_path = './img'
const icon_path = './icons'

const imgLayerBounds = new L.latLngBounds(consts.imgLayerBounds[0], consts.imgLayerBounds[1])
const mapBounds = new L.latLngBounds(consts.mapBounds[0], consts.mapBounds[1])


const checkInsideBounds = (latlng) =>{
    return latlng[0] <= consts.mapBounds[0][0]
        && latlng[0] >= consts.mapBounds[1][0]
        && latlng[1] >= consts.mapBounds[0][1]
        && latlng[1] <= consts.mapBounds[1][1]
}

/**
 * В className записаны все данные для парсинга, чтобы по клику узнать какой это элемент
 * @param type
 * @param code
 * @param index
 * @param list
 * @param icon
 * @returns {*}
 */
const a_icon = function (type, index, list, code, icon) {
    return new L.Icon({
        iconUrl: require(`${icon_path}/${type}/${icon}.svg`),
        iconSize: [40, 40],
        iconAnchor: [20, 20],
        className: `${type}--${list}--${index}--${code}`,
    });
};

const i_icon = function (type, index, list, code, icon) {
    return new L.Icon({
        iconUrl: require(`${icon_path}/${type}/${icon}.svg`),
        iconSize: [40, 40],
        iconAnchor: [20, 20],
        className: `${type}--${list}--${index}--${code}`,
    });
};

const p_icon = function (type, index) {
    return new L.Icon({
        iconUrl: require(`${icon_path}/panorama.svg`),
        iconSize: [40, 40],
        iconAnchor: [20, 20],
        className: `${type}--${index}`,
    });
};

const location_icon = function(available, orientation) {
    let deg = Math.abs(orientation.alfa - 40)
    const div = '<div style="' +
        '    background-image: conic-gradient( rgba(154,187,229,' + consts.compass_alpha + ') 80deg, transparent 0);\n' +
        '    transform: rotate(' + deg + 'deg);"></div>'
    return new L.divIcon(
        {
            className: 'user--position--icon',
            html: available ? div : ""
        }
    )
}


const search_icon = function() {
    return new L.divIcon({className: 'search--position--icon'});
}

const routingControl = ( start, end ) => {
    //start = L.latLng(50.00189, 36.22932), end = L.latLng(50.00271, 36.22311)
    const r = L.Routing.control({
        router: new L.Routing.OSRMv1({
            profile: 'foot',
            generate_hints: false,
            serviceUrl: 'https://routing.openstreetmap.de/routed-foot/route/v1',
            overview: "simplified"
        }),
        collapsible: true,
        geometryOnly: true,
        addWaypoints: false,
        draggableWaypoints: false,
        waypoints: [start, end],
        unitNames: {meters: 'м',kilometers: 'км',yards: 'yd',miles: 'mi',hours: 'год.',minutes: 'хв.',seconds: 'сек.'},
        createMarker: (i, l) => {
            console.log(i, l)
            if(i === 0){
                return L.marker(
                    l.latLng,
                    {
                        icon: L.divIcon({className: 'route-start--icon'})
                    }
                )
            }
            if(i === 1){
                return L.marker(
                    l.latLng,
                    {
                        icon: L.divIcon({className: 'route-end--icon'})
                    }
                )
            }
        }
    })

    return () => r
}

const sheet_config = {
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
}


function App() {

    /*
    Список списков
     */
    const lists = new Map()
    lists.set('animals_mammals', animals_mammals)
    lists.set('animals_birds', animals_birds)
    lists.set('animals_other', animals_other)
    lists.set('infrastructure', infrastructure)
    lists.set('panorams', panorams)

    /*
    Карта готова
     */
    const [mapReady, setMapReady] = useState(false)

    /*
    Элемент по которому кликнули последним
     */
    const [activeElement, setActiveElement] = useState()

    /*
    Ивент загрузки карты onLoad
     */
    const handleOnLoad = () => {
        //console.log("APP LOADED")
    }



    /* -------------------------------------------------------------------------------------------------
    Обработчик клика по карте
     */
    const handleMapClick = (e) => {
        setActiveElement(e)

        setShowSettingsDrawer(false)
        setShowSearchDrawer(false)

        //парсинг строки из списка классов иконки
        if(e.target.classList[0] === 'leaflet-marker-icon'){
            const data = e.target.classList[1].split('--')

            if(data[0] === 'animal'){
                const animal = lists.get(data[1])[data[2]]
                animal.img = require(`${img_path}/animals/${data[3]}.jpg`)
                animal.info_type = 'animal'
                animal.exp_img ? animal.exp_img = require(`${img_path}/expositions/${data[3]}_exp.jpg`) : animal.exp_img = false
                setShowDialog((prev) => ({show: true, data: animal}))
            }

            if(data[0] === 'infrastructure'){
                const object = lists.get(data[1])[data[2]]
                object.img = require(`${img_path}/infrastructure/${data[3]}.jpg`)
                object.info_type = 'infrastructure'
                object.exp_img = false
                setShowDialog((prev) => ({show: true, data: object}))
            }

            if (data[0] === 'panorama'){
                const object = lists.get("panorams")[data[1]]
                object.img_path = require(`${img_path}/panorams/${object.img}.jpg`)
                setShowPanorama((prev) => ({show: true, data: object}))
            }

        }
    }

    /* ----------------------------------------------------------------------------------------------
    Показывать/скрывать диалоговые окна
     */
    // окно с онформацией об объекте
    const [showDialog, setShowDialog] = useState({
        show: false,
        data: {}
    })
    const handleShowInfo = () => {
        setShowDialog( (prev) => ({...showDialog, show: !prev.show}))
    }
    const [showEventTable, setShowEventsTable] = useState({
        show: false,
        config: sheet_config,
        data: {}
    })
    const handleShowEventsTable = () => {
        setShowEventsTable( (prev) => ({...showEventTable, show: !prev.show}))
    }
    const [showPanorama, setShowPanorama] = useState({
        show: false,
        data: {}
    })
    const handleShowPanorama = () => {
        setShowPanorama( (prev) => ({...showPanorama, show: !prev.show}))
    }
    const [showEnableGpsDialog, setEnableGpsDialog] = useState({
        show: false,
    })
    const handleEnableGpsDialog = () => {
        setEnableGpsDialog( (prev) => ({...showEnableGpsDialog, show: !prev.show}))
    }


    // форма настроек -----------------------------------------------------------------------------
    const [showSettingsDrawer, setShowSettingsDrawer] = useState(false)
    const handleSettingsDrawer = () => {
        if(showSearchDrawer) setShowSearchDrawer(false)
        setShowSettingsDrawer( (prev) => !prev )
    }
    const [settings, setSettings] = useState({
        show_animals: true,
        show_infrastructure: true,
        show_zones: false,
        map_mode: consts.defaultMode, //Варианты: osm/map
        max_zoom: 18,
        min_zoom: 16
    })
    const handleSettings = (e) => {
        e.stopPropagation()
        setSettings( (prev) => {
            if(e.target.name !== "map_mode"){
                prev[e.target.name] = !prev[e.target.name]
                return {
                    ...prev,
                }
            }
            else{
                prev["map_mode"] = e.target.value

                //для разных режимов - разный максимальный зум
                if(e.target.value === "osm"){
                    prev.max_zoom = 18
                    prev.min_zoom = 16
                } else {
                    prev.max_zoom = 18
                    prev.min_zoom = 16
                }

                return {
                    ...prev
                }
            }
        } )
    }

    /*
    Твики после запуска карты
    */
    const MapTweaks = () =>{
        const map = useMap()
        map.setMinZoom(settings.min_zoom)
            .setMaxZoom(settings.max_zoom)
            .setMaxBounds(mapBounds)
    }

    //
    // ****************************************   ROUTING MACHINE  ********************************************
    //
    const [routingActive, setRoutingActive] = useState(false)
    const [routingOnScreen, setRoutingOnScreen] = useState(false)
    const [currentRouter, setCurrentRouter] = useState(false)
    const routeEnd = useRef({latitude: false, longitude: false})

    // Инстанс роутера возвращатся из функции routingControl и сохраняется в стейт
    // это нужно чтобы не дублировать много роутеров
    const ShowRoute = () => {
        const map = useMap()
        //обнуление текущего роутера
        if(currentRouter) {
            currentRouter.remove()
            setCurrentRouter(false)
        }
        //запуск нового роутера
        if(routingActive && geoCoords.available && routeEnd.current.latitude) {
            setCurrentRouter(routingControl( L.latLng(geoCoords.latitude, geoCoords.longitude), L.latLng(routeEnd.current.latitude, routeEnd.current.longitude))().addTo(map))

        }
        setRoutingActive(false)
        setRoutingOnScreen(true)

        return null
    }


    //
    const viewRoute = (lat, lng) => {
        //обнуление текущего роутера, если он не пустой
        if(routingActive && currentRouter){
            setCurrentRouter(false)
        }
        setRoutingActive( prev => !prev)
        routeEnd.current = {latitude: lat, longitude: lng}
    }

    const removeRouting = () =>{
        currentRouter.remove()
        setCurrentRouter(false)
        setRoutingOnScreen(false)
    }


    //**********************************************************************************************************

    const deviceOnCenter = useRef(false)// Включить/выключить центрироване по устройству

    // форма поиска -----------------------------------------------------------------------------
    const [searching, setSearching] = useState({
        matches: [],
        processing: false,
    })
    const [showSearchDrawer, setShowSearchDrawer] = useState(false)
    const handleSearchDrawer = () => {
        if(showSettingsDrawer) setShowSettingsDrawer(false)
        setShowSearchDrawer( (prev) => !prev )
    }

    const handleSearch = (e) => {
        e.stopPropagation()
        const string = e.target.value.trim().toLowerCase()
        const matches = []

        if(string.length > 2){
            searching.processing = true
            setSearching( (prev) => ({prev, ...searching}))
            matches.push(
                ...lists.get("animals_mammals").filter( (el) => el.name.toLowerCase().includes(string)),
                ...lists.get("animals_birds").filter( (el) => el.name.toLowerCase().includes(string)),
                ...lists.get("animals_other").filter( (el) => el.name.toLowerCase().includes(string)),
                ...lists.get("infrastructure").filter( (el) => el.name.toLowerCase().includes(string))
            )

            if(matches.length){
                searching.matches = matches
            }else{
                searching.matches = []
            }
            searching.processing = false

            setSearching( (prev) => ({prev, ...searching}))
        }
    }

    const handleMatchClick = (e, el) => {
        e.stopPropagation()
        setShowSearchDrawer( false )
        deviceOnCenter.current = false
        setMapCenter(prev => { return {...prev, changeCenter: true,  coords: el.geodata} })
        setTimeout(
            () => setMapCenter(prev => { return {...prev, changeCenter: false}}), consts.animation_timeout
        )

    }


    /* ----------------------------------------------------------------------------------------
    Смена центра карты
    changeCenter: Флаг, указывающий новый центр
    coords: координаты куда сместить карту
     */
    const [mapCenter, setMapCenter] = useState({
        changeCenter: false,  // для анимации перехода, пока true - мигает круг вокруг точки назначения
        coords: consts.map_center}
    )


    const GoToMapCenter = () => {
        const map = useMap()

        useEffect( () => {
            map.flyTo(mapCenter.coords)
            setTimeout( () => { setMapCenter(prev => { return {...prev, changeCenter: false}}) }, consts.animation_timeout)
        }, [map])

        return null
    }

    const [orientationAvailable, setOrientationAvailable] = useState(false)
    const [orientation, setOrientation] = useState({
        alfa: 0
    })

    const orientationHandler = (e) => {
        orientation.alfa = Math.abs(e.alpha - 360)
        setOrientation( orientation )
    }

    useEffect( () => {
        window.addEventListener("deviceorientationabsolute", orientationHandler, true);
        setOrientationAvailable(true)
    }, [])


    /** --------------------------------------------------------------------------------------
     * Используем Geolocation API
     */
    const [geoCoords, setGeoCoords] = useState({
        latitude: null,
        longitude: null,
        accuracy: null,
        available: false
    })

    useEffect( () =>{

        navigator.geolocation.watchPosition(
            (pos) => {
                setGeoCoords(prev => {
                    return {...prev, available: true, accuracy: pos.coords.accuracy, latitude: pos.coords.latitude, longitude: pos.coords.longitude}
                })
            },
            (err) => {
                console.log(err)
                setGeoCoords( prev => {
                    return {...prev, available: false}
                })
            }
            ,
            {
                enableHighAccuracy: true,
                timeout: 10000,
            }
        )
    }, [])

    /*
    Коллбэк по клику на значок локатора
    Если GPS доступен - перемещает к положению утройства, активирует/деактивирует центрование по устройству
    Если не доступен - показывает диалог с просьбой включить
     */
    const centerGeoPosition = () => {
        if(geoCoords.available){
            deviceOnCenter.current = !deviceOnCenter.current
            setMapCenter( prev => {
                return {
                    ...prev,
                    changeCenter: true,
                    coords: [geoCoords.latitude, geoCoords.longitude]
                }
            })
        } else{
            setEnableGpsDialog({show: true})
        }
    }


    /*
    Отображение в реальном времени маркера поожения устройства
    При включенной опции центрирования - центрирование карты по этим координатам
     */
    const RealTimeGPSposition = () => {
        const map = useMap()
        const [GPSmarker, setGPSmarker] = useState([geoCoords.latitude, geoCoords.longitude], geoCoords.accuracy)
        useEffect( () => {
            if(deviceOnCenter.current) setGPSmarker([geoCoords.latitude, geoCoords.longitude], geoCoords.accuracy)

        }, [])

        if( geoCoords.available ){

            /*
            Тут планируется центрирование карты на 4 сек. вперед,
            чтобы не было самопроизвольных возвратов, пользуемся флагом,
            в котором текущее значение центрирования true/false
             */
           setTimeout( () => deviceOnCenter.current && map.setView([geoCoords.latitude, geoCoords.longitude]), 4000)

            return (
                <>
                    <Marker position={[GPSmarker[0], GPSmarker[1]]} icon={location_icon(orientationAvailable, orientation)}></Marker>
                    <Circle
                        center={[GPSmarker[0], GPSmarker[1]]}
                        radius={geoCoords.accuracy}
                        pathOptions={{fillColor:"rgb(138 183 255)", color:"rgb(138 183 255)",opacity:consts.gps_accuracy_alpha,weight:"1",fillOpacity:consts.gps_accuracy_alpha}}
                    ></Circle>
                </>

            )
        } else {
            return null
        }

    }



    return (
        <div className="App" onLoad={handleOnLoad}>
            <ThemeProvider theme={mytheme}>
                <Container sx={{padding:"0"}}>

                    { consts.appBar === "full" && <AppBar
                        geoCoords={geoCoords}
                        mapCenter={mapCenter}
                        deviceonCenter={deviceOnCenter}
                        centerGeoPosition={centerGeoPosition}
                        handleSearchDrawer={handleSearchDrawer}
                        showSearchDrawer={showSearchDrawer}
                        handleShowEventsTable={handleShowEventsTable}
                        handleSettingsDrawer={handleSettingsDrawer}
                        showSettingsDrawer={showSettingsDrawer}
                    /> }

                    { consts.appBar === "btn" && <AppButtons
                        geoCoords={geoCoords}
                        mapCenter={mapCenter}
                        deviceonCenter={deviceOnCenter}
                        centerGeoPosition={centerGeoPosition}
                        handleSearchDrawer={handleSearchDrawer}
                        showSearchDrawer={showSearchDrawer}
                        handleShowEventsTable={handleShowEventsTable}
                        handleSettingsDrawer={handleSettingsDrawer}
                        showSettingsDrawer={showSettingsDrawer}
                    /> }

                    <Box onClick={(e) => handleMapClick(e)}>
                        <MapContainer
                            center={mapCenter.coords}
                            zoom={17} scrollWheelZoom={false}
                            style={{width:"100%", height:"100vh"}}
                            whenReady={() => setMapReady(true)}
                        >
                            { routingActive  ? <ShowRoute /> : null }
                            { mapCenter.changeCenter && <GoToMapCenter /> }
                            { mapCenter.changeCenter && (<Marker position={mapCenter.coords} icon={search_icon()}></Marker>) }
                            { settings.map_mode === "osm" && <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />}
                            { settings.map_mode=== "map" && <ImageOverlay url={imgLayerMap} bounds={imgLayerBounds}/> }
                            { settings.show_animals && lists.get('animals_mammals').map( (el, index, arr) =>
                                (<Marker
                                    position={[...el.geodata]}
                                    icon={a_icon("animal", index, 'animals_mammals', el.code, el.icon)}
                                    key={index}>
                                </Marker>))
                            }
                            { settings.show_animals && lists.get('animals_birds').map( (el, index, arr) =>
                                (<Marker
                                    position={[...el.geodata]}
                                    icon={a_icon("animal", index, 'animals_birds', el.code, el.icon)}
                                    key={index}>
                                </Marker>))
                            }
                            { settings.show_animals && lists.get('animals_other').map( (el, index, arr) =>
                                (<Marker
                                    position={[...el.geodata]}
                                    icon={a_icon("animal", index, 'animals_other', el.code, el.icon)}
                                    key={index}>
                                </Marker>))
                            }
                            { settings.show_infrastructure && lists.get('infrastructure').map( (el, index, arr) =>
                                (<Marker
                                    position={[...el.geodata]}
                                    icon={i_icon("infrastructure", index, 'infrastructure', el.code, el.icon)}
                                    key={index}>
                                </Marker>))
                            }
                            {/* lists.get('panorams').map( (el, index) =>
                                (<Marker
                                    position={[...el.geodata]}
                                    icon={p_icon("panorama", index, el.code)}
                                    key={index}>
                                </Marker>))
                            */}
                            { geoCoords.available && checkInsideBounds([geoCoords.latitude, geoCoords.longitude]) && <RealTimeGPSposition /> }
                            { mapReady && <MapTweaks /> }
                        </MapContainer>
                        {/* geoCoords.available && <Typography sx={{padding:"5px",position:"fixed",left:"0",bottom:"0",zIndex:"500",fontSize:"14px"}}>{geoCoords.latitude} | {geoCoords.longitude}</Typography>*/}
                        {/* deviceType === "i" && <Typography sx={{padding:"5px",position:"fixed",left:"0",bottom:"0",zIndex:"500",fontSize:"14px"}}>{orientation.alfa}</Typography> */}
                    </Box>
                </Container>
                <Drawer
                    id="search-drawer"
                    sx={{
                        width: consts.drawer_width,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: consts.drawer_width,
                        },
                    }}
                    variant="persistent"
                    anchor="right"
                    open={showSearchDrawer}
                >
                    <DrawerHeader>
                        <IconButton onClick={handleSearchDrawer}>
                            <ChevronRightOutlined />
                        </IconButton>
                        <Typography sx={{fontWeight:"bold"}}>Пошук за назвою</Typography>
                    </DrawerHeader>
                    <SearchAnimalInput handleSearch={handleSearch} handleMatch={handleMatchClick} data={searching} />
                </Drawer>
                <Drawer
                    id="settings-drawer"
                    sx={{
                        width: consts.drawer_width,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: consts.drawer_width,
                        },
                    }}
                    variant="persistent"
                    anchor="right"
                    open={showSettingsDrawer}
                >
                    <DrawerHeader>
                        <IconButton onClick={handleSettingsDrawer}>
                            <ChevronRightOutlined />
                        </IconButton>
                        <Typography sx={{fontWeight:"bold"}}>Налаштування</Typography>
                    </DrawerHeader>
                    <SettingsForm handleSettings={handleSettings} currentSettings={settings} />
                </Drawer>
            </ThemeProvider>

            {showDialog.show && <ShowInfo {...showDialog} handleShow={handleShowInfo} setRoute={viewRoute}/>}
            {showEventTable.show && <ShowEvents {...showEventTable} handleShow={handleShowEventsTable}/>}
            {showPanorama.show && <ShowPanorama {...showPanorama} handleShow={handleShowPanorama} />}
            {showEnableGpsDialog && <ShowEnableGpsDialog {...showEnableGpsDialog} handleShow={handleEnableGpsDialog} />}
            {routingOnScreen ? <IconButton onClick={removeRouting} className="remove-route"><ExploreOffIcon style={{fill:"white"}}/></IconButton> : null }
            <div className="about"><p>Карта працює у тестовому режимі. © КО "Харківський зоопарк</p></div>
        </div>
    );
}

export default App;

/*
                                    <Popup>
                                        <Card sx={{ maxWidth: 260 }}>
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    height="146"
                                                    image={`${img_path}${el.code}.jpg`}
                                                    alt={`"${el.name}"`}
                                                />
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div">
                                                        {el.name}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Lizards are a widespread group of squamate reptiles, with over 6,000
                                                        species, ranging across all continents except Antarctica
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Popup>
 */
const animals_mammals = [ {
        // Укр. название
        name: "Слон азіатський",
        // Уникальный код (животные одного вида могут быть на разных экспозициях)
        // в таком случае код должен отличаться т.к. будут разные фото экспозиций
        // и возможно разные описания
        code: "elephant_1",
        // ID страницы на сайте для фетчинга (пока не используется)
        id: 110,
        // Координаты
        geodata: [50.00378, 36.22350],
        // Описание на укр.
        description: "Завдяки хоботу і величезним вухам, сірий могутній гігант викликає великий інтерес у дітей і дорослих.",
        // Дополнительное фото снизу (обычно это общий вид экспозиции)
        // Файл должен иметь название ${code}_exp.jpg
        exp_img: true,
        // Название svg-файла для иконки
        icon: "elephant"
    },
    {
        name: "Слон азіатський",
        code: "elephant_2",
        id: 110,
        geodata: [50.00404, 36.22432],
        description: "Завдяки хоботу і величезним вухам, сірий могутній гігант викликає великий інтерес у дітей і дорослих.",
        exp_img: true,
        icon: "elephant"
    },
    {
        name: "Жираф",
        code: "giraffe",
        id: 110,
        geodata: [50.00214450735198, 36.22548585203261],
        description: "Жирафи – найвищі і найбільш довгошиї сучасні тварини. Відмінно пристосовані до життя в савані. У них відмінний зір і гарний слух.",
        exp_img: true,
        icon: "giraffe"
    },
    {
        name: "Ведмідь бурий",
        code: "brown_bear_1",
        id: 110,
        geodata: [50.00166, 36.22842],
        description: "Бурий ведмідь один з найбільших наземних хижаків. Голова у звіра важка, губи, як і ніс, чорні, очі маленькі, глибоко посаджені. Хвіст дуже короткий, цілком схований у хутрі. Кігті довгі, до 10 см, особливо на передніх лапах, але мало зігнуті.",
        exp_img: true,
        icon: "brown_bear"
    },
    {
        name: "Ведмідь бурий",
        code: "brown_bear_2",
        id: 110,
        geodata: [50.00145, 36.22774],
        description: "Бурий ведмідь один з найбільших наземних хижаків. Голова у звіра важка, губи, як і ніс, чорні, очі маленькі, глибоко посаджені. Хвіст дуже короткий, цілком схований у хутрі. Кігті довгі, до 10 см, особливо на передніх лапах, але мало зігнуті.",
        exp_img: true,
        icon: "brown_bear"
    },
    {
        name: "Ягуар",
        code: "jaguar",
        id: 110,
        geodata: [50.00168, 36.22763],
        description: "Ягуар – один з найбільших і найкрасивіших представників загону Хижих. Відноситься до групи так званих «великих кішок», в розмірах він поступається тільки тигру і леву, а зовні дуже схожий на леопарда",
        exp_img: true,
        icon: "jaguar"
    },
    {
        name: "Орангутан суматранський",
        code: "orangutan_1",
        id: 110,
        geodata: [50.00422, 36.22558],
        description: "Орангутани – великі антропоїди. Практично все життя орангутани проводять на деревах, по яких пересуваються за допомогою дуже довгих рук (розмах – до 2 м), допомагаючи собі ногами.",
        exp_img: true,
        icon: "orangutan"
    },
    {
        name: "Орангутан суматранський",
        code: "orangutan_2",
        id: 110,
        geodata: [50.00394, 36.22541],
        description: "Орангутани – великі антропоїди. Практично все життя орангутани проводять на деревах, по яких пересуваються за допомогою дуже довгих рук (розмах – до 2 м), допомагаючи собі ногами.",
        exp_img: true,
        icon: "orangutan"
    },
    {
        name: "Лев",
        code: "lion_1",
        id: 110,
        geodata: [50.00287, 36.22482],
        description: "Лев – єдиний представник в сімействі котячих, у кого є грива. Вона буває тільки у самця і починає рости приблизно у 2 роки. Вважається, що грива захищає від ударів пазуристих лап суперників під час поєдинків.",
        exp_img: true,
        icon: "lion"
    },
    {
        name: "Лев",
        code: "lion_2",
        id: 110,
        geodata: [50.00283, 36.22433],
        description: "Лев – єдиний представник в сімействі котячих, у кого є грива. Вона буває тільки у самця і починає рости приблизно у 2 роки. Вважається, що грива захищає від ударів пазуристих лап суперників під час поєдинків.",
        exp_img: true,
        icon: "lion"
    },
    {
        name: "Макаки японські",
        code: "japanese_macaque",
        id: 110,
        geodata: [50.00196, 36.22852],
        description: "Японські макаки відомі тим, що в сильні морози гріються в гарячих джерелах, яких в Японії безліч.",
        exp_img: true,
        icon: "japanese_macaque"
    },
    {
        name: "Бегемот",
        code: "hippo",
        id: 110,
        geodata: [50.00262, 36.2236],
        description: "Найбільший та найсильніший мешканець річок і озер Африки.",
        exp_img: true,
        icon: "hippo"
    },
    {
        name: "Баран гривастий",
        code: "barbary_sheep",
        id: 110,
        geodata: [50.00217, 36.22647],
        description: "Пустельні гори, каньйони і скелі - рідна стихія гривистого барана.",
        exp_img: true,
        icon: "barbary_sheep"
    },
    {
        name: "Сурикат",
        code: "meerkat",
        id: 110,
        geodata: [50.00222, 36.22612],
        description: "Відмінна риса цих витончених звірят - їх лапи, озброєні довгими міцними кігтями, за допомогою яких сурикати добувають їжу і копають глибокі нори.",
        exp_img: true,
        icon: "meerkat"
    },
    {
        name: "Ведмідь гімалайський",
        code: "asian_black_bear",
        id: 110,
        geodata: [50.00126, 36.22705],
        description: "Характерна особливість гімалайського ведмедя - світла пляма у вигляді птаха, що летить, на грудях. Завдяки такому «знаку» його часто називають ще й білогрудий.",
        exp_img: true,
        icon: "asian_black_bear"
    },
    {
        name: "Вовк",
        code: "wolf",
        id: 110,
        geodata: [50.00177, 36.22605],
        description: "Розумний і волелюбний хижак, родич собак всіх порід.",
        exp_img: true,
        icon: "gray_wolf"
    },
    {
        name: "Рись євразійська (звичайна)",
        code: "lynx",
        id: 110,
        geodata: [50.00140, 36.22583],
        description: "Найпівнічніша кішка. Рисі – своєрідні, ні на кого не схожі представники сімейства котячих. У них відносно короткий тулуб і високі сильні ноги.",
        exp_img: true,
        icon: "lynx"
    },
    {
        name: "Білий тигр",
        code: "white_tiger",
        id: 110,
        geodata: [50.00103, 36.22565],
        description: "Білі тигри — це бенгальські тигри з чорно-коричневими смугами на білому хутрі й блакитними очима. Таке забарвлення дуже рідко зустрічається серед диких тварин, але є відносно поширеним в популяціях, які утримуються в неволі.",
        exp_img: true,
        icon: "amur_tiger"
    },
    {
        name: "Тигр амурський ",
        code: "amur_tiger",
        id: 110,
        geodata: [50.0011, 36.22656],
        description: "Амурський тигр - найбільший представник сімейства Котячих.",
        exp_img: true,
        icon: "amur_tiger"
    },
    {
        name: "Гієна плямиста",
        code: "spotted_hyena",
        id: 110,
        geodata: [50.00261, 36.22469],
        description: "Єдиний ссавець, у якого за зовнішніми статевими ознаками не можна відрізнити самця від самки.",
        exp_img: true,
        icon: "spotted_hyena"
    },
    {
        name: "Мара",
        code: "mara",
        id: 110,
        geodata: [50.00342, 36.22520],
        description: "Швидконога й елегантна рідня морської свинки.",
        exp_img: true,
        icon: "mara"
    },
    {
        name: "Верблюд двогорбий",
        code: "camel",
        id: 110,
        geodata: [50.00309, 36.22224],
        description: "У піщану бурю та в сніговий буран не зіб'ється зі шляху.",
        exp_img: true,
        icon: "camel"
    },
    {
        name: "Гуанако",
        code: "guanaco",
        id: 110,
        geodata: [50.00294, 36.22228],
        description: "Дикий родич лами - безгорбий верблюд.",
        exp_img: true,
        icon: "guanaco"
    },
    {
        name: "Канна звичайна",
        code: "kanna",
        id: 110,
        geodata: [50.00173, 36.22484],
        description: "Найбільша з антилоп, що населяє савани Південної і Східної Африки.",
        exp_img: true,
        icon: "kanna"
    },
    {
        name: "Гну блакитний",
        code: "blue_gnu",
        id: 110,
        geodata: [50.00191, 36.22396],
        description: "Гну має неповторний вигляд: голова і роги нагадують бичачі, грива і хвіст – кінські, але насправді це антилопа.",
        exp_img: true,
        icon: "blue_gnu"
    },
    {
        name: "Олень плямистий",
        code: "spotted_deer",
        id: 110,
        geodata: [50.00194, 36.22781],
        description: "Плямисті олені – стрункі тварини легкої статури. Влітку ці тварини червоно-руді з білими плямами, взимку – бурі.",
        exp_img: true,
        icon: "spotted_deer"
    },
    {
        name: "Лемур котячий",
        code: "lemur",
        id: 110,
        geodata: [50.00287, 36.22385],
        description: "Свою назву цей вид лемурів отримав через схожі з домашніми кішками розміри і ходу, а також вміння нявкати і урчати.",
        exp_img: true,
        icon: "lemur"
    },
    {
        name: "Капібара",
        code: "capybara",
        id: 110,
        geodata: [50.0035, 36.2258],
        description: "Добрий та чарівний гігант серед гризунів. Живуть капібари по берегах озер і річок. Прекрасно плавають. Це найбільший гризун на нашій планеті.",
        exp_img: false,
        icon: "capybara"
    },


]


export default animals_mammals
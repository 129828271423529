const animals_birds = [{
    // Укр. название
    name: "Пугач",
    // Уникальный код (животные одного вида могут быть на разных экспозициях)
    // в таком случае код должен отличаться т.к. будут разные фото экспозиций
    // и возможно разные описания
    code: "bubo_1",
    // ID страницы на сайте для фетчинга (пока не используется)
    id: 110,
    // Координаты
    geodata: [50.00169, 36.22786],
    // Описание на укр.
    description: "Пугач – найбільший з сов фауни світу. Як у більшості сов, активність пугача сутінкова і нічна.",
    // Дополнительное фото снизу (обычно это общий вид экспозиции)
    // Файл должен иметь название ${code}_exp.jpg
    exp_img: true,
    // Название svg-файла для иконки
    icon: "eagle-owl"
},
    {
        name: "Пінгвін Гумбольдта",
        code: "penguin",
        id: 110,
        geodata: [50.00366, 36.22730],
        description: "Пінгвін середнього розміру. Мешкає в Південній Америці, його ареал в основному охоплює більшу частину прибережного Перу",
        exp_img: true,
        icon: "penguin"
    },
    {
    name: "Лебідь-шипун",
    code: "swan",
    id: 110,
    geodata: [50.00157, 36.22645],
    description: "Захищаючи гніздо і своє потомство, птахи видають шиплячі звуки, за що і отримали свою назву. Лебідь-шипун - найбільший вид серед всіх лебедів і усього ряду Гусеподібних.",
    exp_img: true,
    icon: "swan"
},
    {
    name: "Гусак гірський",
    code: "barheaded_goose",
    id: 110,
    geodata: [50.002, 36.22707],
    description: "Дрібний стрункий гусак на високих лапах. Ці гуси одні з найбільш високолітаючих птахів.",
    exp_img: true,
    icon: "barheaded_goose"
},{
    name: "Казарка канадська атлантична",
    code: "canada_goose",
    id: 110,
    geodata: [50.00176, 36.22669],
    description: "Найбільший представник свого роду, за розмірами перевершує сірого гусака. Під час годівлі кілька гусей завжди охороняють зграю, виконуючи роль сторожів.",
    exp_img: true,
    icon: "canada_goose"
},
    {
    name: "Фазан золотий",
    code: "golden_pheasant",
    id: 110,
    geodata: [50.00165, 36.22729],
    description: "Один з найяскравіших представників сімейства фазанові. ",
    exp_img: true,
    icon: "golden_pheasant"
}
,
    {
    name: "Мандаринка",
    code: "mandarinka",
    id: 110,
    geodata: [50.00138, 36.22623],
    description: "\"Мандарини\" – так в Китаї називали знатних вельмож, які мають право носити різнокольоровий одяг. Качка-мандаринка отримала свою назву завдяки яскравому вбранню селезня.",
    exp_img: true,
    icon: "mandarinka"
},
    {
    name: "Страус африканський",
    code: "ostrich",
    id: 110,
    geodata: [50.00220, 36.22468],
    description: "Африканський страус – найбільший сучасний птах. Незважаючи на досить великі крила, літати страуси не можуть, зате відмінно бігають завдяки потужним і сильним ногам.",
    exp_img: true,
    icon: "ostrich"
},
    {
    name: "Журавель індійський, або антігона",
    code: "antigona",
    id: 110,
    geodata: [50.00356, 36.22626],
    description: "Найбільший журавель, його зріст в середньому становить 176 см.",
    exp_img: true,
    icon: "crane"
},
    {
    name: "Фламінго рожевий",
    code: "flamingo",
    id: 110,
    geodata: [50.00321, 36.22634],
    description: "Фламінго рожевий (звичайний) - найбільший представник ряду фламінгоподібні. Його батьківщина - лагуни й солоні озера Євразії та Африки.",
    exp_img: false,
    icon: "flamingo"
},
    {
    name: "Ему",
    code: "emu",
    id: 110,
    geodata: [50.00341, 36.22426],
    description: "Великий бігающий птах, разом з кенгуру зображенний на гербі Австралії.",
    exp_img: true,
    icon: "emu"
},
    {
    name: "Лебідь чорний",
    code: "black_swan",
    id: 110,
    geodata: [50.003, 36.22346],
    description: "Австралійський антипод лебедів Півночі, витончений красень.",
    exp_img: true,
    icon: "black_swan"
},
    {
    name: "Пелікан рожевий",
    code: "pelican",
    id: 110,
    geodata: [50.00271, 36.22316],
    description: "Птах-рибалка з великим горловим мішком-сачком.",
    exp_img: true,
    icon: "pelican"
},
    {
    name: "Баклан великий",
    code: "cormorant",
    id: 110,
    geodata: [50.00288, 36.22301],
    description: "Великий баклан – великий птах-рибалка, який чудово пірнає, пропливаючи в гонитві за здобиччю десятки метрів під водою. ",
    exp_img: true,
    icon: "cormorant"
},
    {
    name: "Сип білоголовий",
    code: "vulture",
    id: 110,
    geodata: [50.00255, 36.2224],
    description: "Подовгу ширяє високо в небі, виглядаючи падаль. Зустрічається в Южной Европе, Азії, Північно-Східній та Північній Африці.",
    exp_img: true,
    icon: "vulture"
},
    {
    name: "Орлан-білохвіст",
    code: "orlan_belohvost",
    id: 110,
    geodata: [50.00142, 36.22542],
    description: "Подовгу ширяє високо в небі, виглядаючи падаль. Зустрічається в Южной Европе, Азії, Північно-Східній та Північній Африці.",
    exp_img: true,
    icon: "orlan_belohvost"
},

]

export default animals_birds
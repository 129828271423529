import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import consts from "../consts";

const StyledAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    backgroundColor: consts.default_color,
    flexDirection: "row",
    justifyContent: "space-between",
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${consts.drawer_width}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: consts.drawer_width,
    }),
}));

export default StyledAppBar
import * as React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {FormLabel} from "@mui/material";
import {RadioGroup} from "@mui/material";
import {Radio} from "@mui/material";
import SSwitch from "./styled_switch";
import consts from "../consts";

const SettingsForm = ( props ) => {
    return (
        <Box component="form"
             sx={{
                 '& .MuiTextField-root': { width: '266px', margin:'0 5px', padding:'0'},

             }}>
            <FormControl component="fieldset" variant="standard" sx={{padding:"20px 10px",margin:"0"}}>
                <FormLabel id="map-mode-label" sx={{color:consts.default_color,paddingBottom:"10px", '&.Mui-focused':{color:consts.default_color}}}>Режим відображення карти</FormLabel>
                <RadioGroup
                    aria-labelledby="map-mode-label"
                    defaultValue={consts.defaultMode}
                    name="map_mode"
                    onChange={props.handleSettings}
                >
                    <FormControlLabel value="map" control={<Radio />} label="Картаплан зоопарка" checked={props.currentSettings.map_mode === "map"}/>
                    <FormControlLabel value="osm" control={<Radio />} label='Карта "Open Street Map"'  checked={props.currentSettings.map_mode === "osm"}/>
                </RadioGroup>
            </FormControl>
            <FormControl component="fieldset" variant="standard">
                <FormGroup>
                    <FormControlLabel sx={{padding:"20px 10px",margin:"0"}}
                        control={
                            <SSwitch checked={props.currentSettings.show_animals} onChange={props.handleSettings} name="show_animals" />
                        }
                        label="Відображати експозиції"
                    />
                    <FormControlLabel sx={{padding:"20px 10px",margin:"0"}}
                        control={
                            <SSwitch checked={props.currentSettings.show_infrastructure} onChange={props.handleSettings} name="show_infrastructure" />
                        }
                        label="Відображати інфраструктуру"
                    />
                    {/*<FormControlLabel sx={{padding:"20px 10px",margin:"0"}}
                        control={
                            <SSwitch checked={props.currentSettings.show_zones} onChange={props.handleSettings} name="show_zones" />
                        }
                        label="Відображати зони"
                    />*/}
                </FormGroup>
            </FormControl>
        </Box>
    )
}

export default SettingsForm
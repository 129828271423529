import React, {useEffect, useRef, useState} from "react";

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import DialogContent from '@mui/material/DialogContent';
import useGoogleSheets from "use-google-sheets";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import EventNoteIcon from '@mui/icons-material/EventNote';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';

import {styled} from "@mui/material/styles";

const CompactCell = styled(TableCell)(({ theme }) => ({
    padding: '4px'
}));

const ShowEvents = function(props){

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (props.show) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [props.show]);

    const { data, loading, error } = useGoogleSheets(props.config)

    if(error){
        console.log(error)
    }

    return (
        <div>
            <Dialog
                open={props.show}
                onClose={props.handleShow}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogContent dividers={true} sx={{padding:"0", border:"0"}}>
                    <Card sx={{ maxWidth: 300}} ref={descriptionElementRef}>
                        <IconButton
                            aria-label="close"
                            onClick={props.handleShow}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                backgroundColor: "rgb(72 72 72 / 12%)"
                            }}
                        >
                            <CloseIcon sx={{fill: "white"}}/>
                        </IconButton>
                        <CardHeader title={props.data.name} sx={{fontSize:"1.4rem",paddingBottom:"0"}}>Найближчі події</CardHeader>
                        <CardContent sx={{paddingTop:"8px"}}>
                            { loading ? <CircularProgress /> :
                                (typeof data !== 'undefined') ?  (
                                    <TableContainer component={Paper} size="small" >
                                        <Table aria-label="simple table">
                                            <TableBody>
                                                {data[0]['data'].map((row, ind) => (
                                                    <>
                                                        <TableRow key={row['date']} sx={{backgroundColor:'#fff9e5', '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <CompactCell sx={{padding:'0'}}>
                                                            <EventNoteIcon style={{fill:"#135030",float:'left'}} />
                                                        </CompactCell>
                                                        <CompactCell component="th" scope="row" sx={{fontWeight:'bold',maxWidth:'95px',fontSize:'.75rem'}}>
                                                           {row['date'] + ' ' + row['time']}
                                                        </CompactCell>
                                                        <CompactCell>
                                                            <RoomOutlinedIcon style={{fill:"#135030"}}/>
                                                        </CompactCell>
                                                        <CompactCell sx={{fontSize:'.75rem'}}>
                                                            {row['location']}
                                                        </CompactCell>
                                                        </TableRow>
                                                        <TableRow key={ind}>
                                                            <CompactCell colSpan="4"><div
                                                                dangerouslySetInnerHTML={{__html : `<h4 style="margin: 5px 0">${row['event']}</h4><p style="margin: 0">${row['description']}</p>`}}
                                                            /></CompactCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                            ) : null}
                        </CardContent>
                    </Card>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default ShowEvents